import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withAcceptance from './withAcceptance';
import TAndCAccordion from './TAndCAccordion';
import Button from './../../components/Button/Button';
import Tick from './../../components/Tick/Tick';
import Cross from './../../components/Cross/Cross';
import { isActive } from './../../utils/date';
import './TAndC.css';
import { useDispatch, useSelector } from 'react-redux';
import ClearningHouseApi from '../../store/api/clearningHouse';
import { setNextEnabled } from '../../store/actions/globalActions';
import { handleReturnToPayroll } from '../../store/thunks/global'

const BeamTAndCWithAcceptance = withAcceptance(
  TAndCAccordion,
  'beam',
  'beam',
  'Beam terms and conditions',
  'I have read and accept the terms and conditions and product information disclosed in the Beam Product Disclosure Statement and I have read Precision’s Privacy Policy and acknowledge that it explains how personal information is collected, used and disclosed by Precision in connection with Beam.'
);

const DirectDebitAuthorityTAndCWithAcceptance = withAcceptance(
  TAndCAccordion,
  'direct-debit-authority',
  'direct-debit-authority',
  'Direct Debit terms and conditions',
  'I have read and accept the Direct Debit Request Service Agreement'
);

const SunsuperTAndCWithAcceptance = withAcceptance(
  TAndCAccordion,
  'sunsuper',
  'sunsuper',
  'Become an Australian Retirement Trust participating employer',
  'I apply to become a participating employer of Australian Retirement Trust'
);

const TAndC = props => {
  const [newDirectDebitAuthorityIds, setDirectDebitAuthorityIds] = useState([]);
  const [beamAccepted, setBeamAccepted] = useState(false);
  const [
    directDebitAuthorityAccepted,
    setDirectDebitAuthorityAccepted
  ] = useState(false);
  const [artParticipantAccepted, setArtParticipantAccepted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState([]);
  const [error, setError] = useState(null);
  const [isInitialized, setInitialized] = useState(false);

  const dispatch = useDispatch();
  const {
    participant,
    clientParticipantNumber,
    userAcceptingTermsAndConditions,
    payrollName,
    redirectUrl
  } = useSelector(state => state.participant.data);
  const { employerRegisterSuper, isARTSelected } = useSelector(
    state => state.global.data
  );

  const handleAcceptance = (name, value) => {
    switch (name) {
      case 'beam':
        setBeamAccepted(value);
        break;
      case 'directDebitAuthority':
        setDirectDebitAuthorityAccepted(value);
        break;
      case 'sunsuper':
        setArtParticipantAccepted(value);
        break;
      default:
    }
  };

  const updateSuccessStatus = res => {
    setStatus({ ...status, res });
  };

  const handleClick = () => {
    setSubmitting(true);
    setStatus([]);
    setError(null);

    acceptBeam()
      .then(res => {
        return acceptDDAs();
      })
      .then(res => {
        return acceptArtParticipant();
      })
      .then(res => {
        updateSuccessStatus(
          'All terms and conditions are accepted, redirecting...'
        );

        setTimeout(() => {
          setSubmitting(false);

          // Navigate to next step
          props.history.push({
            pathname: '/finish',
            hash: props.location.hash
          });
        }, 3000);
      })
      .catch(error => {
        setSubmitting(false);
        setError(error);
      });
  };

  const acceptBeam = async () => {
    try {
      const res = await ClearningHouseApi.acceptBeamTerms(
        userAcceptingTermsAndConditions,
        clientParticipantNumber
      );
      return await Promise.resolve('Beam');
    } catch (error) {
      if (error.response && error.response.data) {
        return Promise.reject(
          `Beam acceptance request failed: ${error.response.data.errorCode} - ${
            error.response.data.message
          }`
        );
      } else {
        return Promise.reject('Beam acceptance request failed');
      }
    }
  };

  const acceptDDAs = async () => {
    if (participant.directDebitAuthorities.length > 0) {
      try {
        const res = await ClearningHouseApi.acceptDDAs(
          userAcceptingTermsAndConditions,
          clientParticipantNumber,
          newDirectDebitAuthorityIds
        );
        return await Promise.resolve('Direct Debit Request');
      } catch (error) {
        if (error.response && error.response.data) {
          return Promise.reject(
            `Direct Debit acceptance request failed: ${
              error.response.data.errorCode
            } - ${error.response.data.message}`
          );
        } else {
          return Promise.reject('Direct Debit acceptance request failed');
        }
      }
    } else {
      return Promise.resolve('Direct Debit Request passed');
    }
  };

  const acceptArtParticipant = () => {
    if ((employerRegisterSuper || isARTSelected) && artParticipantAccepted) {
      return (
        ClearningHouseApi.acceptSuperTerms(
          userAcceptingTermsAndConditions,
          clientParticipantNumber,
          participant.employers[0].employerId
        )
          // CHECK THIS CHRISTINA
          .then(res => {
            return Promise.resolve(
              'Australian Retirement Trust participanting employer'
            );
          })
          .catch(error => {
            console.log(
              `Australian Retirement Trust participanting employer acceptance request failed: ${
                error.errorCode
              } - ${error.message}`
            );
            return Promise.reject(
              `Australian Retirement Trust participanting employer acceptance request failed: ${
                error.errorCode
              } - ${error.message}`
            );
          })
      );
    } else {
      return Promise.resolve(
        'Australian Retirement Trust participanting employer passed'
      );
    }
  };

  const initData = () => {
    dispatch(setNextEnabled(false));
    setDirectDebitAuthorityIds(
      participant.directDebitAuthorities
        .filter(authority => {
          return (
            isActive(authority.endDate) &&
            authority.termsAndConditionsAcceptanceDate === null
          );
        })
        .map(authority => authority.directDebitAuthorityId)
    );
  };

  useEffect(
    () => {
      document.title = `Beam - Terms & Conditions`;
      if (participant && !isInitialized) {
        setInitialized(true);
        initData();
      }
    },
    [participant]
  );

  const directDebitRequirementsMet =
    !newDirectDebitAuthorityIds ||
    newDirectDebitAuthorityIds.length === 0 ||
    directDebitAuthorityAccepted;

  const artParticipantRequirementsMet =
    (!employerRegisterSuper && !isARTSelected) || artParticipantAccepted;

  const canGoNext =
    beamAccepted && directDebitRequirementsMet && artParticipantRequirementsMet;

  return (
    <div>
      <section className="Section Section--leading">
        <h1>Terms and conditions</h1>
      </section>
      <section className="Section">
        <BeamTAndCWithAcceptance onAcceptance={handleAcceptance} />
      </section>

      {newDirectDebitAuthorityIds &&
        newDirectDebitAuthorityIds.length > 0 && (
          <section className="Section">
            <DirectDebitAuthorityTAndCWithAcceptance
              onAcceptance={handleAcceptance}
            />
          </section>
        )}
      {(employerRegisterSuper || isARTSelected) && (
        <section className="Section">
          <SunsuperTAndCWithAcceptance onAcceptance={handleAcceptance} />
        </section>
      )}
      <section className="Section PageNavGroup">
        <Button
          type="button"
          primary
          hollow
          customclass="Button--cancel"
          onClick={() => {
            dispatch(handleReturnToPayroll(payrollName, redirectUrl));
          }}
        >
          <span>Cancel</span>
        </Button>
        <Link
          className="Button Button--primary Button--hollow Button--prev"
          to={{
            pathname: '/default-fund',
            hash: props.location.hash
          }}
        >
          <span>Back</span>
        </Link>
        <Button
          primary
          customclass="Button--next"
          disabled={!canGoNext || submitting}
          onClick={handleClick}
        >
          <span>{submitting ? 'Submitting...' : 'Next'}</span>
        </Button>
      </section>
      <div className="StatusPanel">
        <ul>
          {status.length > 0 &&
            status.map(update => (
              <li key={update}>
                <Tick />
                {update}
              </li>
            ))}
          {error != null && (
            <li key={error}>
              <Cross />
              {error}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(props => <TAndC {...props} />);
